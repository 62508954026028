<template>
  <div class="page_content page_content_coupon">
    <div class="content_header d-flex align-items-center">
      <span>優惠碼管理</span>
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="d-flex mb-3">
          <a
            v-if="!edit_mode"
            href="#"
            class="btn btn_primary px-2"
            @click.prevent="init_couponcode(true)"
          >
            <span>建立優惠</span>
          </a>
          <a
            v-else
            href="#"
            class="btn btn_primary ml-auto px-2"
            @click.prevent="init_couponcode(), init_validate();"
          >
            <span>返回</span>
          </a>
        </div>
        <div
          v-if="!edit_mode"
          class="table-responsive"
        >
          <table class="table table-borderless">
            <thead class="thead-light">
              <tr>
                <th
                  scope="col"
                >
                  對應系統
                </th>
                <th
                  scope="col"
                >
                  優惠名稱
                </th>
                <th
                  scope="col"
                >
                  商品/付款代碼
                </th>
                <th
                  scope="col"
                >
                  數量/金額
                </th>
                <th
                  scope="col"
                >
                  優惠門檻
                </th>
                <th
                  scope="col"
                >
                  生效日期
                </th>
                <th
                  scope="col"
                >
                  失效日期
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(coupon, index) in filter_Data"
                :key="index"
              >
                <td class="">
                  <div>
                    {{ coupon.systemCode }}
                  </div>
                </td>
                <td class="">
                  <a
                    href="#"
                    class="text-color_primary"
                    @click.prevent="get_coupon_detail(coupon.couponSerial)"
                  >
                    {{ coupon.name }}
                  </a>
                  <a
                    v-if="!coupon.status"
                    href="#"
                    class="d-inline-block btn btn_outline_danger rounded-sm px-2"
                    @click.prevent="delete_couponcode(coupon.couponSerial)"
                  >刪除</a>
                </td>
                <td class="">
                  <div>{{ coupon.objectSerial }}</div>
                </td>
                <td class="">
                  <div>{{ coupon.objectValue }}</div>
                </td>
                <td class="">
                  <div>{{ coupon.optionLimitAmount }}</div>
                </td>
                <td class="">
                  <div>{{ coupon.dtEffective }}</div>
                </td>
                <td class="">
                  <div>{{ coupon.dtInvalid }}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <ul
          v-if="filter_Data.length > 0 && !edit_mode"
          class="pagination"
        >
          <li
            class="pagination-item"
            :class="{ 'pagination-item-disabled': !pagination.has_pre }"
          >
            <a
              href="#"
              class="pagination-link"
              aria-label="Previous"
              @click.prevent="change_page(pagination.current_page - 1)"
            >
              <i class="icon-chevron-thin-left" />
            </a>
          </li>
          <li
            v-for="(page, index) in pagination.filter_range_page"
            :key="index"
            class="pagination-item"
          >
            <a
              href="#"
              class="pagination-link"
              :class="{ 'pagination-link-active': pagination.current_page === page.index }"
              @click.prevent="change_page(page.index)"
            >{{ page.index }}</a>
          </li>
          <li
            class="pagination-item"
            :class="{ 'pagination-item-disabled': !pagination.has_next }"
          >
            <a
              href="#"
              class="pagination-link"
              aria-label="Next"
              @click.prevent="change_page(pagination.current_page + 1)"
            >
              <i class="icon-chevron-thin-right" />
            </a>
          </li>
        </ul>
        <div
          v-if="edit_mode"
          class="edit_section"
        >
          <div class="section_body container-fluid">
            <ul class="nav nav-tabs mb-3">
              <li class="nav-item">
                <a
                  href="#"
                  class="nav-link"
                  :class="{ 'active': nav_tabs === 'coupon_info' }"
                  @click.prevent="switch_nav('coupon_info')"
                >優惠資料</a>
              </li>
              <li
                v-if="!edit_section_new"
                class="nav-item"
              >
                <a
                  href="#"
                  class="nav-link"
                  :class="{ 'active': nav_tabs === 'coupon_code' }"
                  @click.prevent="switch_nav('coupon_code')"
                >折扣碼</a>
              </li>
              <li
                v-if="!edit_section_new"
                class="nav-item"
              >
                <a
                  href="#"
                  class="nav-link"
                  :class="{ 'active': nav_tabs === 'coupon_exchange' }"
                  @click.prevent="switch_nav('coupon_exchange')"
                >兌換紀錄</a>
              </li>
              <li
                v-if="!edit_section_new"
                class="nav-item"
              >
                <a
                  href="#"
                  class="nav-link"
                  :class="{ 'active': nav_tabs === 'coupon_order' }"
                  @click.prevent="switch_nav('coupon_order')"
                >兌換訂單</a>
              </li>
            </ul>
            <template v-if="nav_tabs === 'coupon_info'">
              <div class="row">
                <div
                  v-if="!edit_section_new"
                  class="col-12 col-xl-4"
                >
                  <div class="form-group d-flex align-items-center">
                    <label for="coupon_couponSerial">流水號</label>
                    <input
                      id="coupon_couponSerial"
                      v-model="couponcode_content.couponSerial"
                      v-validate="'required'"
                      type="text"
                      name="流水號"
                      class="form-control form_input"
                      :class="{'is-invalid': errors.has('detail.流水號')}"
                      data-vv-scope="detail"
                    >
                  </div>
                </div>
                <div class="col-12 col-xl-4">
                  <div class="form-group d-flex align-items-center">
                    <label for="coupon_systemCode">對應系統</label>
                    <select
                      id="coupon_systemCode"
                      v-model="couponcode_content.systemCode"
                      v-validate="'required'"
                      name="對應系統"
                      class="form-control form_input"
                      :disabled="edit_section_new ? false : true"
                      :class="{'is-invalid': errors.has('detail.對應系統')}"
                      data-vv-scope="detail"
                    >
                      <option
                        :value="'ALL'"
                      >
                        不限
                      </option>
                      <option
                        v-for="item in system_data"
                        :key="item.systemCode"
                        :value="item.systemCode"
                      >
                        {{ item.systemName }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-12 col-xl-4">
                  <div class="form-group d-flex align-items-center">
                    <label for="coupon_type">優惠類型</label>
                    <select
                      id="coupon_type"
                      v-model="couponcode_content.type"
                      v-validate="'required'"
                      name="優惠類型"
                      class="form-control form_input"
                      :disabled="edit_section_new ? false : true"
                      :class="{'is-invalid': errors.has('detail.優惠類型')}"
                      data-vv-scope="detail"
                      @change="couponcode_content.type === 'point' ? couponcode_content.objectSerial = 'Q0101-0010000K' : ''"
                    >
                      <option
                        value=""
                        disabled
                        selected
                        hidden
                      >
                        類型
                      </option>
                      <option
                        v-for="item in option.couponType"
                        :key="item.code"
                        :value="item.code"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-12 col-xl-4">
                  <div class="form-group d-flex align-items-center">
                    <label for="coupon_name">優惠名稱</label>
                    <input
                      id="coupon_name"
                      v-model="couponcode_content.name"
                      v-validate="'required'"
                      type="text"
                      name="優惠名稱"
                      class="form-control form_input"
                      :class="{'is-invalid': errors.has('detail.優惠名稱')}"
                      data-vv-scope="detail"
                      placeholder="優惠名稱"
                    >
                  </div>
                </div>
                <div class="col-12 col-xl-4">
                  <div class="form-group d-flex align-items-center">
                    <label for="coupon_objectValue">數量或金額</label>
                    <input
                      id="coupon_objectValue"
                      v-model.number="couponcode_content.objectValue"
                      v-validate="'required'"
                      type="number"
                      name="數量或金額"
                      class="form-control form_input"
                      :class="{'is-invalid': errors.has('detail.數量或金額')}"
                      data-vv-scope="detail"
                      placeholder="數量或金額"
                      :disabled="edit_section_new ? false : true"
                    >
                  </div>
                </div>
                <div class="col-12 col-xl-4">
                  <div class="form-group d-flex align-items-center">
                    <label for="coupon_optionLimitAmount">優惠門檻</label>
                    <input
                      id="coupon_optionLimitAmount"
                      v-model.number="couponcode_content.optionLimitAmount"
                      v-validate="'required'"
                      type="number"
                      name="優惠門檻"
                      class="form-control form_input"
                      :class="{'is-invalid': errors.has('detail.優惠門檻')}"
                      data-vv-scope="detail"
                      placeholder="最低購物車門檻金額"
                      :disabled="edit_section_new ? false : true"
                    >
                  </div>
                </div>
                <div
                  v-if="edit_section_new"
                  class="col-12 col-xl-4"
                >
                  <div class="form-group d-flex align-items-center">
                    <label for="coupon_optionQuantity">生成數量</label>
                    <input
                      id="coupon_optionQuantity"
                      v-model.number="couponcode_content.optionQuantity"
                      v-validate="'required'"
                      type="number"
                      name="生成數量"
                      class="form-control form_input"
                      :class="{'is-invalid': errors.has('detail.生成數量')}"
                      data-vv-scope="detail"
                      placeholder="折扣碼生成數量"
                    >
                  </div>
                </div>
                <div
                  v-if="edit_section_new"
                  class="col-12 col-xl-4"
                >
                  <div class="form-group d-flex align-items-center">
                    <label for="coupon_optionAccountAllow">兌換數量</label>
                    <input
                      id="coupon_optionAccountAllow"
                      v-model.number="couponcode_content.optionAccountAllow"
                      v-validate="'required'"
                      type="number"
                      name="兌換數量"
                      class="form-control form_input"
                      :class="{'is-invalid': errors.has('detail.兌換數量')}"
                      data-vv-scope="detail"
                      placeholder="單筆折扣碼最大兌換帳戶數"
                    >
                  </div>
                </div>
                <div
                  v-if="edit_section_new"
                  class="col-12 col-xl-4"
                >
                  <div class="form-group d-flex align-items-center">
                    <label for="coupon_setCouponCode">設定折扣碼</label>
                    <input
                      id="coupon_setCouponCode"
                      v-model="couponcode_content.setCouponCode"
                      type="text"
                      name="設定折扣碼"
                      class="form-control form_input"
                      placeholder="強制設定折扣碼"
                    >
                  </div>
                </div>
                <div
                  v-if="edit_section_new"
                  class="col-12 col-xl-4"
                >
                  <div class="form-group d-flex align-items-center">
                    <label for="coupon_optionLimitPerMonth">限定一次/月</label>
                    <div class="form-check form-check-inline">
                      <input
                        id="coupon_optionLimitPerMonth_true"
                        v-model="couponcode_content.optionLimitPerMonth"
                        v-validate="'required'"
                        class="form-check-input"
                        type="radio"
                        name="限定設定"
                        :value="true"
                        data-vv-scope="detail"
                      >
                      <label
                        class="form-check-label"
                        for="coupon_optionLimitPerMonth_true"
                      >是</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        id="coupon_optionLimitPerMonth_false"
                        v-model="couponcode_content.status"
                        v-validate="'required'"
                        class="form-check-input"
                        type="radio"
                        name="限定設定"
                        :value="false"
                        data-vv-scope="detail"
                      >
                      <label
                        class="form-check-label"
                        for="coupon_optionLimitPerMonth_false"
                      >否</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-xl-6">
                  <div class="form-group d-flex align-items-center">
                    <label for="coupon_dtPublic">上架日期</label>
                    <!-- :min="`${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`"
                      :max="`${new Date().getFullYear() + 1}-${new Date().getMonth() + 1}-${new Date().getDate()}`" -->
                    <input
                      id="coupon_dtPublic"
                      v-model="couponcode_content.dtPublic"
                      v-validate="'required'"
                      type="date"
                      name="上架日期"
                      class="form-control form_input"
                      :class="{'is-invalid': errors.has('detail.上架日期')}"
                      data-vv-scope="detail"
                      placeholder="YYYY-MM-DD"
                    >
                  </div>
                </div>
                <div class="col-12 col-xl-6">
                  <div class="form-group d-flex align-items-center">
                    <label for="coupon_dtUnPublic">最後日期</label>
                    <!-- :min="`${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`"
                      :max="`${new Date().getFullYear() + 1}-${new Date().getMonth() + 1}-${new Date().getDate()}`" -->
                    <input
                      id="coupon_dtUnPublic"
                      v-model="couponcode_content.dtUnPublic"
                      v-validate="'required'"
                      type="date"
                      name="最後日期"
                      class="form-control form_input"
                      :class="{'is-invalid': errors.has('detail.最後日期')}"
                      data-vv-scope="detail"
                      placeholder="YYYY-MM-DD"
                    >
                  </div>
                </div>
                <div class="col-12 col-xl-6">
                  <div class="form-group d-flex align-items-center">
                    <label for="coupon_dtEffective">生效日期</label>
                    <!-- :min="`${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`"
                      :max="`${new Date().getFullYear() + 1}-${new Date().getMonth() + 1}-${new Date().getDate()}`" -->
                    <input
                      id="coupon_dtEffective"
                      v-model="couponcode_content.dtEffective"
                      v-validate="'required'"
                      type="date"
                      name="生效日期"
                      class="form-control form_input"
                      :class="{'is-invalid': errors.has('detail.生效日期')}"
                      data-vv-scope="detail"
                      placeholder="YYYY-MM-DD"
                    >
                  </div>
                </div>
                <div class="col-12 col-xl-6">
                  <div class="form-group d-flex align-items-center">
                    <label for="coupon_dtInvalid">失效日期</label>
                    <!-- :min="`${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`"
                      :max="`${new Date().getFullYear() + 1}-${new Date().getMonth() + 1}-${new Date().getDate()}`" -->
                    <input
                      id="coupon_dtInvalid"
                      v-model="couponcode_content.dtInvalid"
                      v-validate="'required'"
                      type="date"
                      name="失效日期"
                      class="form-control form_input"
                      :class="{'is-invalid': errors.has('detail.失效日期')}"
                      data-vv-scope="detail"
                      placeholder="YYYY-MM-DD"
                    >
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-xl-12">
                  <div class="form-group d-flex align-items-center">
                    <label for="coupon_objectSerial">商品/付款方式代碼</label>
                    <input
                      id="coupon_objectSerial"
                      v-model="couponcode_content.objectSerial"
                      v-validate="'required'"
                      type="text"
                      name="商品/付款方式代碼"
                      class="form-control form_input"
                      :class="{'is-invalid': errors.has('detail.商品/付款方式代碼')}"
                      data-vv-scope="detail"
                      placeholder="商品代碼或付款方式代碼 ex:Q0101-0010000K"
                      :disabled="edit_section_new ? false : true"
                    >
                  </div>
                </div>
                <div
                  v-if="edit_section_new"
                  class="col-12 col-xl-12"
                >
                  <div class="form-group d-flex align-items-center">
                    <label for="coupon_optionAccountTimes">單一帳戶數可使用次數</label>
                    <input
                      id="coupon_optionAccountTimes"
                      v-model.number="couponcode_content.optionAccountTimes"
                      v-validate="'required'"
                      type="number"
                      name="單一帳戶數可使用次數"
                      class="form-control form_input"
                      :class="{'is-invalid': errors.has('detail.單一帳戶數可使用次數')}"
                      data-vv-scope="detail"
                      placeholder="單一帳戶數可使用次數"
                    >
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end">
                <a
                  href="#"
                  class="btn btn_primary rounded-0"
                  @click.prevent="edit_couponcode(edit_section_new)"
                >{{ edit_section_new === true ? '建立' : '更新' }}</a>
                <a
                  href="#"
                  class="btn btn_default rounded-0"
                  @click.prevent="init_couponcode(), init_validate()"
                >關閉</a>
              </div>
            </template>
            <template v-if="nav_tabs === 'coupon_code'">
              <div class="table-responsive">
                <table class="table table-borderless">
                  <thead class="thead-light">
                    <tr>
                      <th
                        scope="col"
                      >
                        折扣碼
                      </th>
                      <th
                        scope="col"
                      >
                        已兌換/最大兌換
                      </th>
                      <th
                        scope="col"
                      >
                        使用次數/位
                      </th>
                      <th
                        scope="col"
                      >
                        限制一次/月
                      </th>
                      <th
                        scope="col"
                      >
                        最後兌換日期
                      </th>
                      <th
                        scope="col"
                      >
                        建立日期
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in couponcode_content.items"
                      :key="index"
                      :class="{ 'table_disabled': !item.status }"
                    >
                      <td class="">
                        <div>
                          {{ item.couponCode }}
                        </div>
                      </td>
                      <td class="">
                        <div :class="{ 'text-danger': item.optionAccountExchange === item.optionAccountAllow }">
                          {{ item.optionAccountExchange }}/{{ item.optionAccountAllow }}
                        </div>
                      </td>
                      <td class="">
                        <div>
                          {{ item.optionAccountTimes }}
                        </div>
                      </td>
                      <td class="">
                        <div>{{ item.optionLimitPerMonth ? '限制' : '無限制' }}</div>
                      </td>
                      <td class="">
                        <div>{{ item.dtExchnage }}</div>
                      </td>
                      <td class="">
                        <div>{{ item.dtCreate }}</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </template>
            <template v-if="nav_tabs === 'coupon_exchange'">
              <div class="table-responsive">
                <table class="table table-borderless">
                  <thead class="thead-light">
                    <tr>
                      <th
                        scope="col"
                      >
                        折扣碼
                      </th>
                      <th
                        scope="col"
                      >
                        兌換人
                      </th>
                      <th
                        scope="col"
                      >
                        最後使用日期
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(exchange, index) in couponcode_content.exchange"
                      :key="index"
                    >
                      <td class="">
                        <div>
                          {{ exchange.couponCode }}
                        </div>
                      </td>
                      <td class="">
                        <div>
                          {{ exchange.fullname }}
                        </div>
                        <div>
                          {{ exchange.celnum }}
                        </div>
                      </td>
                      <td class="">
                        <div>
                          {{ exchange.dtLastUse }}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </template>
            <template v-if="nav_tabs === 'coupon_order'">
              <div class="table-responsive">
                <table class="table table-borderless">
                  <thead class="thead-light">
                    <tr>
                      <th
                        scope="col"
                      >
                        折扣碼
                      </th>
                      <th
                        scope="col"
                      >
                        訂單編號
                      </th>
                      <th
                        scope="col"
                      >
                        訂購人
                      </th>
                      <th
                        scope="col"
                      >
                        金額
                      </th>
                      <th
                        scope="col"
                      >
                        訂單日期
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(order, index) in couponcode_content.order"
                      :key="index"
                    >
                      <td class="">
                        <div>
                          {{ order.couponCode }}
                        </div>
                      </td>
                      <td class="">
                        <div>
                          {{ order.orderSerial }}
                        </div>
                      </td>
                      <td class="">
                        <div>
                          {{ order.purFullname }}
                        </div>
                        <div>
                          {{ order.purCelnum }}
                        </div>
                      </td>
                      <td class="">
                        <div>
                          {{ order.amountTotal }}
                        </div>
                      </td>
                      <td class="">
                        <div>
                          {{ order.orderDate }}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </template>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  API_GET_COUPONCODE_LIST,
  API_GET_COUPONCODE_DETAIL,
  API_CREATE_COUPON,
  API_UPDATE_COUPON,
  API_DELETE_COUPON,
} from '@/plugins/api';

export default {
  data() {
    return {
      now_page: 'couponcode',
      couponcode_list: [], /* 優惠碼列表 */
      couponcode_content: {
        systemCode: '',
        type: '',
        optionLimitPerMonth: false,
      },
      edit_mode: false, /* 編輯模式 */
      edit_section_new: false, /* 編輯的區塊是否為新 */
      nav_tabs: 'coupon_info',
      pagination: {
        /* 分頁設定 */
        filter_page_size: 20,
        total_pages: 1,
        current_page: 1,
        has_pre: false,
        has_next: false,
        filter_range: 3,
        filter_range_page: [],
      },
    };
  },
  computed: {
    ...mapGetters('system', ['system', 'option']),
    system_data: {
      get() {
        const vm = this;
        const data = vm.system.slice();
        return data;
      },
    },
    filter_Data: {
      get() {
        const vm = this;
        /* 先給空陣列，避免 couponcode_list 還沒拿到資料出錯 */
        let couponcodeData = [];
        couponcodeData = vm.couponcode_list.slice();
        const dataLen = couponcodeData.length;
        const nowPage = vm.pagination.current_page;
        vm.pagination.total_pages = Math.ceil(
          dataLen / vm.pagination.filter_page_size,
        );
        vm.pagination.filter_range_page = [];
        for (let index = 1; index <= vm.pagination.total_pages; index += 1) {
          if (index >= (nowPage - vm.pagination.filter_range) && index <= (nowPage + vm.pagination.filter_range)) {
            vm.pagination.filter_range_page.push({
              index,
            });
          }
        }
        if (nowPage > 1) {
          vm.pagination.has_pre = true;
        } else {
          vm.pagination.has_pre = false;
        }
        if (nowPage === vm.pagination.total_pages) {
          vm.pagination.has_next = false;
        } else {
          vm.pagination.has_next = true;
        }
        return couponcodeData.filter(
          (item, index) => index < nowPage * vm.pagination.filter_page_size
            && index >= (nowPage - 1) * vm.pagination.filter_page_size,
        );
      },
      set() {},
    },
  },
  async mounted() {
    const vm = this;
    await vm.$store.dispatch('page_load', true);
    await vm.$store.dispatch('now_page', vm.now_page);
    await vm.get_system();
    await vm.get_system_option();
    await vm.get_couponcode_list();
    await vm.$store.dispatch('page_load', false);
  },
  methods: {
    ...mapActions('system', ['get_system', 'get_system_option']),
    /* 初始化 pagination */
    async init_pagination() {
      const vm = this;
      vm.pagination = {
        /* 分頁設定 */
        filter_page_size: 20,
        total_pages: 1,
        current_page: 1,
        has_pre: false,
        has_next: false,
        filter_range: 3,
        filter_range_page: [],
      };
    },
    /* 初始化 couponcode_content 內容 */
    async init_couponcode(isNew = false) {
      const vm = this;
      vm.couponcode_content = {
        systemCode: 'ALL',
        type: '',
        optionLimitPerMonth: false,
      };
      vm.edit_mode = isNew;
      vm.edit_section_new = isNew;
      vm.nav_tabs = 'coupon_info';
    },
    /* 初始化驗證 */
    async init_validate() {
      const vm = this;
      vm.$validator.pause();
      vm.$nextTick(() => {
        vm.$validator.reset();
        vm.$validator.errors.clear('detail');
        vm.$validator.fields.items.forEach((field) => field.reset());
        vm.$validator.fields.items.forEach((field) => vm.errors.remove(field));
        vm.$validator.resume();
      });
    },
    /* 切換頁數 */
    change_page(page = 1) {
      const vm = this;
      vm.pagination.current_page = page;
    },
    /* 取得優惠碼列表 */
    async get_couponcode_list() {
      const vm = this;
      await vm.$store.dispatch('page_load', true);
      const response = await API_GET_COUPONCODE_LIST();
      const res = response.data;
      if (res.code === '0000') {
        vm.couponcode_list = res.data;
        await vm.init_pagination();
        await vm.init_couponcode();
        await vm.init_validate();
      }
      await vm.$store.dispatch('page_load', false);
    },
    /* 取得優惠細節 */
    async get_coupon_detail(couponCode) {
      const vm = this;
      await vm.$store.dispatch('page_load', true);
      const response = await API_GET_COUPONCODE_DETAIL(couponCode);
      const res = response.data;
      if (res.code === '0000') {
        vm.couponcode_content = res.data;
        vm.edit_mode = true;
        await vm.init_validate();
      }
      await vm.$store.dispatch('page_load', false);
    },
    /* 刪除門市 */
    async delete_couponcode(couponCode) {
      const vm = this;
      const isConfirm = await vm.$swal({
        title: '確定刪除此優惠?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: '取消',
        confirmButtonText: '刪除',
      });
      if (isConfirm.isConfirmed) {
        await vm.$store.dispatch('page_load', true);
        const response = await API_DELETE_COUPON(couponCode);
        const res = response.data;
        if (res.code === '0000') {
          vm.$swal({
            icon: 'success',
            title: '成功!',
            text: res.text,
            timer: 1000,
            confirmButtonText: '關閉視窗',
          });
          await vm.get_couponcode_list();
        }
        await vm.$store.dispatch('page_load', false);
      }
    },
    async switch_nav(content) {
      const vm = this;
      vm.nav_tabs = content;
    },
    /* 編輯/建立 優惠碼 */
    async edit_couponcode(isnew) {
      const vm = this;
      switch (isnew) {
        case true:
          await vm.create_couponcode();
          break;
        case false:
          await vm.update_couponcode();
          break;
        default:
          break;
      }
    },
    async create_couponcode() {
      const vm = this;
      const validateResult = await vm.$validator.validateAll('detail');
      if (validateResult) {
        const data = {
          systemCode: vm.couponcode_content.systemCode,
          type: vm.couponcode_content.type,
          name: vm.couponcode_content.name,
          objectSerial: vm.couponcode_content.objectSerial,
          objectValue: vm.couponcode_content.objectValue,
          optionLimitAmount: vm.couponcode_content.optionLimitAmount,
          dtPublic: vm.couponcode_content.dtPublic,
          dtUnPublic: vm.couponcode_content.dtUnPublic,
          dtEffective: vm.couponcode_content.dtEffective,
          dtInvalid: vm.couponcode_content.dtInvalid,
          optionQuantity: vm.couponcode_content.optionQuantity,
          optionAccountAllow: vm.couponcode_content.optionAccountAllow,
          optionAccountTimes: vm.couponcode_content.optionAccountTimes,
          optionLimitPerMonth: vm.couponcode_content.optionLimitPerMonth,
          setCouponCode: vm.couponcode_content.setCouponCode || '',
        };
        const response = await API_CREATE_COUPON(data);
        const res = response.data;
        if (res.code === '0000') {
          vm.$swal({
            icon: 'success',
            title: '成功!',
            text: res.text,
            confirmButtonText: '關閉視窗',
          });
          await vm.get_couponcode_list();
        }
      } else {
        let errorText = '';
        vm.errors.items.forEach((item, index) => {
          errorText += `<p class="text-left">${index + 1}. 請正確填寫 ${item.field} 欄位</p>`;
        });
        vm.$swal({
          icon: 'error',
          title: '錯誤!',
          html: errorText,
          confirmButtonText: '關閉視窗',
        });
      }
    },
    async update_couponcode() {
      const vm = this;
      const validateResult = await vm.$validator.validateAll('detail');
      if (validateResult) {
        const data = {
          couponSerial: vm.couponcode_content.couponSerial,
          name: vm.couponcode_content.name,
          dtPublic: vm.couponcode_content.dtPublic,
          dtUnPublic: vm.couponcode_content.dtUnPublic,
          dtEffective: vm.couponcode_content.dtEffective,
          dtInvalid: vm.couponcode_content.dtInvalid,
        };
        const response = await API_UPDATE_COUPON(data);
        const res = response.data;
        if (res.code === '0000') {
          vm.$swal({
            icon: 'success',
            title: '成功!',
            text: res.text,
            confirmButtonText: '關閉視窗',
          });
          await vm.get_couponcode_list();
        }
      } else {
        let errorText = '';
        vm.errors.items.forEach((item, index) => {
          errorText += `<p class="text-left">${index + 1}. 請正確填寫 ${item.field} 欄位</p>`;
        });
        vm.$swal({
          icon: 'error',
          title: '錯誤!',
          html: errorText,
          confirmButtonText: '關閉視窗',
        });
      }
    },
  },
};
</script>
